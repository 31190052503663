import {
  EditType,
  TEditField,
  TStoredValue,
} from "src/data/editField/editFieldTypes"
import { formatTimeString } from "src/utils/l10n"

export function getDisplayValue<ISetting, IPayload>(
  conf: TEditField<ISetting, IPayload>,
  value: TStoredValue | null
) {
  switch (conf.type) {
    case EditType.DROPDOWN:
      return (
        conf.options.find((option) => option.value === value)?.name ||
        conf.defaultValue
      )
    case EditType.DROPDOWN_SEARCH:
      return conf.defaultValue?.name
    case EditType.INPUT_NUMBER:
    case EditType.SLIDER:
      return `${value} ${conf.unit}`
    case EditType.SLIDER_RANGE: {
      const [start, end] = (value as string).split("-")
      const clockType = conf.clockType || "24"
      return `${formatTimeString(start, clockType)} - ${formatTimeString(
        end,
        clockType
      )}`
    }
    case EditType.TOGGLE:
      return value === conf.trueVal ? "On" : "Off"

    default:
      return value
  }
}

/** Check all elements of an array, return true if they are equal */
// eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
const allEqual = (arr: any[]): boolean => arr.every((v) => v === arr[0])

/**
 * If all device config values are the same, return that value; otherwise return
 * default value
 */
export function getStoredValue<Setting, Payload>(
  editField: TEditField<Setting, Payload>,
  deviceConfigs: Setting[],
  blockedByPlan: boolean
): TStoredValue {
  if (blockedByPlan) {
    // Sometimes the BE lies, and returns truthy values settings that should be
    // false when they are blocked by the subscription plan. For now we use this
    // workaround, but ideally BUG-641 should be solved.
    return editField.type === EditType.DROPDOWN_SEARCH
      ? editField.defaultValue?.name || ""
      : editField.defaultValue
  }

  const values = deviceConfigs.map((c) => editField.storedValue(c))
  const storedValue0 = values[0]
  if (allEqual(values) && storedValue0 !== undefined) {
    return storedValue0
  }
  if (editField.type === EditType.DROPDOWN_SEARCH) {
    return editField.defaultValue?.name || "" // Has not been tested in bulk edit
  }
  return editField.defaultValue
}
